import { global } from '@storybook/global';
import { dedent } from 'ts-dedent';
import { logger } from '@storybook/client-logger';

var ADDON_ID="storybook/background",PARAM_KEY="backgrounds";var {document,window}=global,isReduceMotionEnabled=()=>window.matchMedia("(prefers-reduced-motion: reduce)").matches,getBackgroundColorByName=(currentSelectedValue,backgrounds=[],defaultName)=>{if(currentSelectedValue==="transparent")return "transparent";if(backgrounds.find(background=>background.value===currentSelectedValue))return currentSelectedValue;let defaultBackground=backgrounds.find(background=>background.name===defaultName);if(defaultBackground)return defaultBackground.value;if(defaultName){let availableColors=backgrounds.map(background=>background.name).join(", ");logger.warn(dedent`
        Backgrounds Addon: could not find the default color "${defaultName}".
        These are the available colors for your story based on your configuration:
        ${availableColors}.
      `);}return "transparent"},clearStyles=selector=>{(Array.isArray(selector)?selector:[selector]).forEach(clearStyle);},clearStyle=selector=>{let element=document.getElementById(selector);element&&element.parentElement.removeChild(element);},addGridStyle=(selector,css)=>{let existingStyle=document.getElementById(selector);if(existingStyle)existingStyle.innerHTML!==css&&(existingStyle.innerHTML=css);else {let style=document.createElement("style");style.setAttribute("id",selector),style.innerHTML=css,document.head.appendChild(style);}},addBackgroundStyle=(selector,css,storyId)=>{let existingStyle=document.getElementById(selector);if(existingStyle)existingStyle.innerHTML!==css&&(existingStyle.innerHTML=css);else {let style=document.createElement("style");style.setAttribute("id",selector),style.innerHTML=css;let gridStyleSelector=`addon-backgrounds-grid${storyId?`-docs-${storyId}`:""}`,existingGridStyle=document.getElementById(gridStyleSelector);existingGridStyle?existingGridStyle.parentElement.insertBefore(style,existingGridStyle):document.head.appendChild(style);}};

export { ADDON_ID, PARAM_KEY, addBackgroundStyle, addGridStyle, clearStyles, getBackgroundColorByName, isReduceMotionEnabled };
